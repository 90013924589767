@import "variables.scss";
@import "media-queries.scss";

html {
    box-sizing: border-box;
    overflow-x: hidden;
    background-color: #141517;
}

body {
    margin: 0;

    margin-right: auto;
    margin-left: auto;

    background-color: $background-color !important;
}

a {
    color: inherit;
    text-decoration: none;
}

* {
    box-sizing: border-box;
}

.no-select {
    -webkit-user-select: none;
    /* Safari */
    -ms-user-select: none;
    /* IE 10 and IE 11 */
    user-select: none;
    /* Standard syntax */
}

::-webkit-scrollbar {
    width: 8px;
}

::-webkit-scrollbar-track {
    display: none;
}

::-webkit-scrollbar-thumb {
    transition: background-color 200ms ease;

    border-radius: 10px;

    background-color: #495057;

    &:hover {
        background-color: #dee2e6;
    }
}
