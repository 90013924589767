@import "media-queries.scss";
@import "variables.scss";

.Homepage {
    box-sizing: border-box;
    background-color: $background-color;

    background-image: radial-gradient(at right bottom, rgb(229, 231, 235), rgb(156, 163, 175), rgb(75, 85, 99));

    margin-left: auto;
    margin-right: auto;

    padding: 5rem;
    padding-top: 3rem;

    @include lt-lg {
        padding: 2.5rem;
    }

    max-width: 3000px;

    // may need to allow this to grow later
    // might be good for scrolling because of the bottom bar
    min-height: 100svh;

    position: relative;
    display: flex;
    flex-direction: column;

    justify-content: center;
    align-items: center;
}

.boring-logo-image {
    position: absolute;
    top: 2rem;
    left: 2rem;

    user-select: none;
    width: 10%;

    // width: 1280px;
    // height: auto;
}

.login-wrapper {
    min-height: 100vh;
    width: 100vw;
    display: flex;

    flex-direction: row;

    > .login-image {
        @include gt-sm {
            flex-grow: 1;
            object-fit: cover;
            background: url("../../public/factory.png") center / cover;
        }

        flex-grow: 0;
        z-index: 900;
    }

    > .login-container {
        padding: 3rem;
        flex-grow: 1;

        box-shadow: rgba(0, 0, 0, 0.4) 0px 7px 29px 0px;

        z-index: 1000;

        @include lt-md {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        @include gt-sm {
            min-width: 30rem;
            flex-grow: 0;
        }

        & > * {
            position: relative;
            max-width: 400px;
            margin-left: auto;
            margin-right: auto;

            @include lt-md {
                flex-grow: 1;
            }
        }
    }
}
