// do some fancy things to style a table, why not.

// fancy css nonsense.

.standard-table {
    th {
        text-align: left;
        padding-left: 8px;
        padding-bottom: 8px;
    }
}

.tableitem {
    transition:
        background-color 200ms ease,
        border-color 200ms ease;
    border-left: 2px solid transparent;

    &.selected {
        border-color: #228be6;
        background-color: #212529;
    }

    &:active {
        background-color: lighten(#212529, 5%);
    }
}

.sortable-header {
    transition: background-color 200ms ease;

    &:hover {
        background-color: #212529;
    }
}
