.system-card {
    width: 300px;
    height: 350px;
    margin: 8px 0px;

    box-shadow: rgba(16, 17, 19, 0.5) 2px 5px 10px;
    transition: box-shadow 200ms ease;

    border: 1px solid transparent;

    &:hover {
        box-shadow: rgba(16, 17, 19, 0.9) 2px 5px 10px;
    }

    > .card-container {
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 100%;

        justify-content: space-between;

        .card-title {
            text-align: center;
            font-size: 1.75rem;
            font-weight: 600;
            margin: 1rem;
            margin-top: 1.25rem;
        }

        > .card-middle {
            width: 100%;
            flex-grow: 1;
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;

            > * {
                margin-top: 1rem;
            }

            > .card-icon {
                &.playing {
                    animation: spin 3s linear infinite;
                }
            }
        }
    }
}

.dashboard {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
