$background-color: #101113;
$primary-color: #f8f9fa;

$color-1: #635985;
$color-2: #443c68;
$color-3: #393053;

$pop-color: #b09af9;

$edge-padding: 2rem;
$dynamic-width: minmax(100%, 2000px);
